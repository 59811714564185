<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Administración del Difunto"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="p-inputgroup field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span
              ><strong>Nombre Familiar/Cliente: </strong>
              <span class="p-invalid">*</span></span
            >
            <Dropdown
              v-model="clienteSelected"
              :options="clientes"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Seleccione un cliente"
              class="p-sm"
              :filter="true"
            />
            <Button
              icon="pi pi-user"
              class="p-button-info p-button-sm"
              v-tooltip.top="'Nuevo Cliente'"
              @click="activarCliente"
              @actualizarClientes="cargarClientes"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Nombre Fallecido(a): <span class="p-invalid">*</span></span
            >
            <Textarea
              v-model="data_difunto.nombre"
              v-tooltip.top="'Nombre Completo'"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Nacionalidad:<span class="p-invalid">*</span></span
            >
            <Textarea
              v-model="data_difunto.nacionalidad"
              v-tooltip.top="'Nacionalidad'"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">
              Ciudad:<span class="p-invalid">*</span></span
            >
            <Textarea v-model="data_difunto.ciudad" rows="1" autoResize />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Edad: <span class="p-invalid">*</span></span
            >
            <Textarea
              v-model="data_difunto.edad"
              class="text-right"
              placeholder="edad"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Sexo: <span class="p-invalid">*</span></span
            >
            <Dropdown
              id="sexo"
              v-model="data_difunto.sexo"
              :options="sexo"
              optionLabel="label"
              placeholder="Seleccione un sexo..."
            >
            </Dropdown>
            <small class="p-invalid" v-if="errors.sexo">{{
              errors.sexo[0]
            }}</small>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Peso: <span class="p-invalid">*</span></span
            >
            <Dropdown
              v-model="data_difunto.peso"
              :options="peso"
              optionLabel="label"
              placeholder="Seleccione un peso..."
            >
            </Dropdown>
            <small class="p-invalid" v-if="errors.peso">{{
              errors.peso[0]
            }}</small>
          </div>
        </div>

        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Estado Civil: <span class="p-invalid">*</span></span
            >
            <Textarea v-model="data_difunto.estado_civil" rows="1" autoResize />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">
              C.I.: <span class="p-invalid">*</span></span
            >
            <Textarea v-model="data_difunto.ci" rows="1" autoResize />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Fecha Fallecimiento: <span class="p-invalid">*</span>
            </span>
            <InputText
              v-model="data_difunto.fecha_del_fallecimiento"
              type="date"
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"
              >Lugar Fallecimiento: <span class="p-invalid">*</span>
            </span>
            <Textarea
              v-model="data_difunto.lugar_del_fallecimiento"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">Marca Pasos: </span>
            <Dropdown
              v-model="data_difunto.marcapaso"
              :options="marcapaso"
              optionLabel="label"
              placeholder="Seleccione un marcapaso..."
            >
            </Dropdown>
            <small class="p-invalid" v-if="errors.marcapaso">{{
              errors.marcapaso[0]
            }}</small>
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span
              ><strong
                >Causas del Fallecimiento:
                <span class="p-invalid">*</span></strong
              ></span
            >
            <Textarea
              v-model="data_difunto.causa_del_fallecimiento"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <!--<div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span><strong>Último Domicilio:</strong></span>
            <Textarea
              v-model="data_difunto.ultimo_domicilio"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span><strong>Nro. Certificado de Difunción:</strong></span>
            <Textarea
              v-model="data_difunto.nro_certificado_de_defuncion"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span><strong>CDGSC:</strong></span>
            <Textarea v-model="data_difunto.cdgsc" rows="1" autoResize />
          </div>
        </div>-->

        <div class="field col-12 md:col-6" v-show="false">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">Estado: </span>
            <Dropdown
              v-model="data_difunto.estado"
              :options="estados"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione un estado"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarDifunto"
        :loading="enviado"
      />
    </template>
  </Dialog>
  <ClienteCreate
    :mostrarModal="clienteModal"
    :show="clienteModal"
    :cliente="cliente"
    @actualizarClientes="cargarClientes"
  >
    <!-- @closeModal="clienteModal = false"
      @actualizarClientes="cargarClientes" -->
  </ClienteCreate>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import DifuntoService from "@/service/DifuntoService";
import ClienteCreate from "../clientes/ClienteCreate.vue";

export default {
  emits: ["closeModal", "actualizarListado"],
  components: {
    ClienteCreate,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    difunto: {
      type: Object,
      default() {
        return {};
      },
    },
    nombre_cliente: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      activarModal: this.mostrar,
      clienteModal: false,
      mostrarModal: this.show,
      data_difunto: this.difunto,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      cliente: { estado: { label: "ACTIVO", value: 1 } },
      clientes: [],
      clienteSelected: null,
      marcapaso: [
        { label: "SI", value: "SI" },
        { label: "NO", value: "NO" },
      ],
      sexo: [
        { label: "MASCULINO", value: "MASCULINO" },
        { label: "FEMENINO", value: "FEMENINO" },
      ],

      peso: [
        { label: "Entre 40 - 100", value: "Entre 40 - 100" },
        { label: "Entre 101 - 120", value: "Entre 101 - 120" },
        { label: "Entre 121 - 160", value: "Entre 121 - 160" },
      ],
      // sobrepeso: false,
    };
  },
  difuntoService: null,
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
    this.difuntoService = new DifuntoService();
  },
  mounted() {
    this.cargarClientes();
  },
  computed: {
    edadMesesDifunto() {
      if (this.data_difunto.edad) {
        let edad = new Date(this.data_difunto.edad);
        let fecha_actual = new Date();
        let edades = fecha_actual.getFullYear() - edad.getFullYear();
        let meses = fecha_actual.getMonth() - edad.getMonth();
        if (
          meses < 0 ||
          (meses === 0 && fecha_actual.getDate() < edad.getDate())
        ) {
          edades--;
          meses += 12;
        }
        return edades + " años y " + meses + " meses";
      }
      return "";
    },
  },
  methods: {
    /* cargarClientes() {
      this.clienteService.getClientesAll().then((data) => {
        this.clientes = data || [];
      });
    },*/
    cargarClientes(cliente = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          this.clientes = response;
          if (cliente) {
            this.clienteSelected = cliente.id;
          }
          this.clientes = response || [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cerrarModalCliente() {
      this.ClienteModal = false;
      this.cliente = {};
    },
    activarClienteModal() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        //tipo: { label: "PARTICULAR", value: "PARTICULAR" },
      };
    },
    validacionesForm() {
      if (!this.clienteSelected) {
        this.errors.clienteSelected = ["Seleccione un cliente"];
      }
      if (!this.data_difunto.nombre) {
        this.errors.nombre = ["Ingrese el nombre del fallecido"];
      }
      if (!this.data_difunto.nacionalidad) {
        this.errors.nacionalidad = ["Ingrese la nacionalidad del fallecido"];
      }
      if (!this.data_difunto.ciudad) {
        this.errors.ciudad = ["Ingrese la ciudad del fallecido"];
      }
      if (!this.data_difunto.sexo) {
        this.errors.sexo = ["Seleccione el sexo del fallecido"];
      }
      if (!this.data_difunto.estado_civil) {
        this.errors.estado_civil = ["Ingrese el estado civil del fallecido"];
      }
      if (!this.data_difunto.ci) {
        this.errors.ci = ["Ingrese la cedula de identidad del fallecido"];
      }
      if (!this.data_difunto.fecha_del_fallecimiento) {
        this.errors.fecha_del_fallecimiento = [
          "Ingrese la fecha de fallecimiento del fallecido",
        ];
      }

      if (!this.data_difunto.lugar_del_fallecimiento) {
        this.errors.lugar_del_fallecimiento = [
          "Ingrese el lugar de fallecimiento del fallecido",
        ];
      }
      if (!this.data_difunto.causa_del_fallecimiento) {
        this.errors.causa_del_fallecimiento = [
          "Ingrese la causa de fallecimiento del fallecido",
        ];
      }
      if (!this.data_difunto.marcapaso) {
        this.errors.marcapaso = ["Seleccione si el fallecido tiene marcapaso"];
      }
      if (!this.data_difunto.peso) {
        this.errors.peso = ["Seleccione el peso del fallecido"];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarDifunto() {
      this.errors = {};
      this.enviado = true;
      if (!this.validacionesForm()) {
        this.enviado = false;
        return;
      }

      /* if (
        // !this.data_difunto.nombre_cliente ||
        !this.data_difunto.nombre ||
        !this.data_difunto.nacionalidad ||
        !this.data_difunto.ciudad ||
        !this.data_difunto.sexo ||
        !this.data_difunto.estado_civil ||
        !this.data_difunto.ci ||
        !this.data_difunto.fecha_del_fallecimiento ||
        !this.data_difunto.lugar_del_fallecimiento ||
        !this.data_difunto.causa_del_fallecimiento ||
        !this.data_difunto.marcapaso ||
        !this.data_difunto.peso ||
        this.clienteSelected <= 0
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail:
            "Seleccione un cliente, nombre del fallecido, nacionalidad, ciudad, fecha de nacimiento, sexo, estado civil, cedula de identidad, fecha de fallecimiento, lugar de fallecimiento, causa de fallecimiento, peso, marca pasos",
          life: 3000,
        });
        this.enviado = false;
        return;
      } */
      if (this.difunto.id) {
        let difunto_enviar = {
          ...this.data_difunto,
          estado: this.data_difunto.estado.value,
          cliente_id: this.clienteSelected,
          marcapaso: this.data_difunto.marcapaso.value,
          sexo: this.data_difunto.sexo.value,
          peso: this.data_difunto.peso.value,
        };
        this.difuntoService.updateDifunto(difunto_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_difunto;
        this.data_difunto = {
          ...this.data_difunto,
          estado: this.data_difunto.estado.value,
          sexo: this.data_difunto.sexo.value,
          marcapaso: this.data_difunto.marcapaso.value,
          peso: this.data_difunto.peso.value,
          cliente_id: this.clienteSelected || null,
        };
        this.difuntoService.saveDifunto(this.data_difunto).then((data) => {
          try {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_difunto = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.difunto);
              this.ocultarDialog();
            }
            this.enviado = false;
          } catch (error) {
            console.log(error);
          }
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    difunto(val) {
      this.data_difunto = val;
      if (val.cliente_id) {
        this.clienteSelected = val.cliente_id;
      }
      this.data_difunto.estado = parseFloat(val.estado.value);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-inputgroup {
  .p-inputtext {
    color: #0062ff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
//a todos los span coor azul
span {
  color: #00346b;
}

.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
</style>
