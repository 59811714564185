<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Administración</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Difuntos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Difuntos
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Difunto Crear' in auth.user.permissions"
          v-tooltip.top="'Nuevo Difunto'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2 p-button-lg"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtdifuntos"
        :value="difuntos"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="20"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Difuntos"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :lazy="true"
        :totalRecords="totalRecords"
        v-model:filters="buscar"
        :rowsPerPageOptions="[20, 500, 1000]"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        stripedRows
        showGridlines
        selectionMode="single click"
        @row-dblclick="editDifunto($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column
          field="nombre_cliente"
          header="CLIENTE"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre Completo..."
            />
          </template>
        </Column>
        <Column
          field="nombre"
          header="NOMBRE DIFUNTO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre Difunto..."
            />
          </template>
        </Column>
        <Column field="nacionalidad" header="NACIONALIDAD">
          <template #body="{ data }">
            {{ data.nacionalidad }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <DifuntoCreate
      :show="difuntoDialog"
      :difunto="difunto"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarDifuntos"
    >
    </DifuntoCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import DifuntoService from "@/service/DifuntoService";
import { useAuth } from "@/stores";
import DifuntoCreate from "@/module/difuntos/DifuntoCreate.vue";

export default {
  components: {
    DifuntoCreate,
  },
  data() {
    return {
      difuntos: null,
      cargando: true,
      difuntoDialog: false,
      difunto: {},
      buscar: {},
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      lazyParams: {},
      totalRecords: 0,
    };
  },
  difuntoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.difuntoService = new DifuntoService();
    this.initBuscar();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtdifuntos.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.cargarDifuntos();
  },
  methods: {
    onPage(event) {
      this.lazyParams = event;
      this.cargarDifuntos();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarDifuntos();
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Difunto Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editDifunto(datos);
          },
        },
        {
          label: "Ver Detalle",
          disabled: "Difunto Ver Detalle" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-eye",
          command: () => {
            this.verDetalle(datos);
          },
        },
      ];
    },
    cargarDifuntos() {
      this.cargando = true;
      setTimeout(() => {
        this.difuntoService
          .getDifuntosAll({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.difuntos.total;

            this.difuntos = data.difuntos.data || [];
            this.cargando = false;

            this.difuntos.forEach((difunto) => {
              difunto.created_at = new Date(difunto.created_at);
              difunto.updated_at = new Date(difunto.updated_at);
              difunto.estado = {
                label: this.textoEstado(difunto.estado),
                value: difunto.estado,
              };
            });
          });
      }, Math.random() * 1000 + 250);
    },
    openNuevo() {
      this.difunto = {
        estado: { label: "Activo", value: 1 },
      };
      console.log(this.difunto);
      this.enviado = false;
      this.difuntoDialog = true;
    },
    ocultarDialog() {
      this.difuntoDialog = false;
      this.enviado = false;
    },
    ocultalModalCliente() {
      this.difuntoDialog = false;
      this.difunto = {};
    },

    editDifunto(difunto) {
      this.difunto = {
        ...difunto,
        estado: {
          label: this.textoEstado(difunto.estado.value),
          value: difunto.estado.value,
        },
        sexo: {
          label: difunto.sexo,
          value: difunto.sexo,
        },
        peso: {
          label: difunto.peso,
          value: difunto.peso,
        },
        marcapaso: {
          label: difunto.marcapaso,
          value: difunto.marcapaso,
        },
      };

      this.difuntoDialog = true;
    },
    textoEstado(estado) {
      return estado == 0 ? "Inactivo" : "Activo";
    },

    exportCSV() {
      this.$refs.dtdifuntos.exportCSV();
    },
    deleteCliente() {
      this.difuntoService.deleteCliente(this.difunto).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.mensaje,
            life: 3000,
          });
          this.cargarDifuntos();
          this.deleteClienteDialog = false;
          this.difunto = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.mensaje,
            life: 3000,
          });
          this.deleteClienteDialog = false;
        }
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.difuntos.length; i++) {
        if (this.difuntos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        nombre_cliente: { value: null, matchMode: FilterMatchMode.EQUALS },
        nombre: { value: null, matchMode: FilterMatchMode.CONTAINS }, //difunto
      };
    },
    verDetalle(difunto) {
      this.$router.push(`/difunto/${difunto.id}/detalle`);
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
