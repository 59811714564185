import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class DifuntoService {
  async getDifuntosAll(parametros) {
    const queryParams = parametros
      ? Object.keys(parametros)
          .map(
            (k) =>
              encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
          )
          .join("&")
      : "";
    const clientes = await fetchWrapper.get(`${ruta}/difunto?` + queryParams);
    return clientes;
  }
  async saveDifunto(difunto) {
    const nuevoDifunto = await fetchWrapper.post(`${ruta}/difunto`, difunto);
    return nuevoDifunto;
  }
  async getAllDifunto() {
    return await fetchWrapper.get(`${ruta}/difunto`);
  }
  async updateDifunto(difunto) {
    const difuntoActualizado = await fetchWrapper.put(
      `${ruta}/difunto/` + difunto.id,
      difunto
    );
    return difuntoActualizado;
  }
}
